<template>
    <div class="text-light">

        <topnav></topnav>
        <div class="page-header header-filter clear-filter black-filter" data-parallax="true"
             style="background-image: url('./assets/img/home_back1.png');transform: translate3d(0px, 0px, 0px);">
            <div class="container">

                <div class="row">
                    <div class="col-md-8 ml-auto mr-auto">
                        <div class="brand">
                            <h2 class="font-weight-bold">{{((details ||{}).welcome_info || {}).top_header_text}}</h2>
                            <p class="h4">{{((details ||{}).welcome_info || {}).top_header_sub_text}}</p>
                            <button class="btn btn-atvyellow btn-lg font-weight-bold" @click="register">Register For
                                Free, Fall in love &
                                Upgrade
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="main main-raised" style="margin: 0px; background-color: #1C212E">
            <div class="section section-basic"
                 :style="{ 'background-image': 'url(' + ((details ||{}).welcome_info || {}).center_header_image1 + ')',transform: 'translate3d(0px, 0px, 0px)','background-position':'center'}"
            >

                <div class="container-fluid custom-container-fluid">
                    <div class="row">
                        <div class="col-md-8 mt-md-5">
                            <h2 class="mb-0 font-weight-bold">{{((details ||{}).welcome_info ||
                                {}).center_top_header_text}}</h2>
                            <h3 class="mt-0 mb-4">{{((details ||{}).welcome_info || {}).center_sub_header_text}}</h3>
                            <p class="font-weight-bold">{{((details ||{}).welcome_info || {}).center_subs_header_text}}
                            </p>
                        </div>
                        <div class="col-md-4">
                            <img
                                v-lazy="((details ||{}).welcome_info || {}).center_header_imgae_ontop?((details ||{}).welcome_info || {}).center_header_imgae_ontop:'../../public/assets/img/home_side_image.png'"
                                style="width: 321px; height: 459px; object-fit: cover" alt="" class="img-fluid">
                        </div>
                    </div>
                </div>
            </div>
            <div class="section">
                <loading
                    :active.sync="isLoading"
                    :can-cancel="false"
                    loader="dots"
                    :is-full-page="fullPage"
                    :height="height"
                    :width="width"
                    background-color="#6b6969"
                ></loading>
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 text-center">
                            <h2>{{((details ||{}).welcome_info || {}).buttom_header_text1}}</h2>
                            <p>{{((details ||{}).welcome_info || {}).buttom_sub_header_text}}</p>
                        </div>
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-2 col-sm-6 col-xs-6 col-6"
                                     v-for="(m,i) in ((details ||{}).movies || []).slice(0,6)" :key="i">
                                    <div class="card cursor-pointer"
                                         @click="movie_info =m;poster_image=m.image;showVideo(m.trailler_url)">
                                        <img v-lazy="m.image?m.image:'../../public/assets/img/movies/1.png'"
                                             class="card-img-top" style="height: 274px; object-fit: cover;" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footernav></footernav>
        </div>
        <div
            class="modal fade"
            id="movieModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
            data-backdrop="static"
        >
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="text-center">
                            <h3 class="text-orange mt-2 font-weight-bold">{{movie_info.post_title}}(Trailer)</h3>
                        </div>

                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                @click="stopPlayer()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <video poster="capture.jpg" id="player" playsinline controls>
                            <source
                                src=""
                                type="video/mp4"/>
                            <source
                                src=""
                                type="video/webm"/>
                        </video>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-orange button" data-dismiss="modal" aria-label="Close"
                                @click="stopPlayer()">Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    /* eslint-disable */
    import axios from "axios";
    import Loading from "vue-loading-overlay";
    import "vue-loading-overlay/dist/vue-loading.css";
    import Plyr from "plyr";


    export default {
        beforeRouteEnter(to, from, next) {
            next(vm => {
                if (vm.$store.getters.loggedIn) {
                    vm.$router.push("/home");
                }
            });
        },
        name: "Home",
        data() {
            return {
                isLoading: false,
                fullPage: false,
                height: 128,
                width: 128,
                userInfo: this.$store.state.userInfo,
                details: {},
                movie_info: {},
                movie_player: '',
                poster_image: ''
            };
        },
        components: {
            topnav: () => import('@/components/topNav'),
            footernav: () => import('@/components/footer'),
            Loading
        },
        methods: {
            register() {
                if (this.userInfo) {
                    this.$router.push('/subscription')
                } else {
                    this.$router.push('/register')

                }
            },
            triggerPlayer(videoUrl) {
                this.movie_player = new Plyr("#player", {
                    debug: false,
                    settings: ["speed"]
                });
                this.movie_player.source = {
                    type: 'video',
                    title: 'Example title',
                    sources: [
                        {
                            src: videoUrl ? videoUrl : '',
                            type: 'video/mp4',
                            size: 720,
                        },
                        {
                            src: videoUrl ? videoUrl : '',
                            type: 'video/webm',
                            size: 1080,
                        },
                    ],
                    poster: this.poster_image ? this.poster_image : '',
                }
                if(videoUrl) {
                    this.movie_player.play();
                }
            },
            showVideo(videoUrl) {
                openModal('#movieModal')
                if (this.movie_player) {
                    this.movie_player.destroy();
                    this.triggerPlayer(videoUrl)
                    return;
                }
                this.triggerPlayer(videoUrl)

            },
            stopPlayer() {
                if (this.movie_player) {
                    this.movie_player.stop()
                }
            },
            getAllInfo() {
                this.isLoading = true;
                axios.get('https://api.aukiss.tv/api/landinginfo').then((res) => {
                    this.details = res.data.data
                }).finally(() => {
                    this.isLoading = false;
                })
            }
        },
        created() {
            document.title = 'Home || Aukiss TV';
            this.getAllInfo();
        },
    };
</script>

<style scoped>
    .card-img-top {
        border-radius: 10px !important;
    }

    .card {
        border-radius: 10px !important;
    }

    @media screen and (max-width: 599px) and (min-width: 300px) {
        .card-img-top {
            height: auto !important;
        }
    }

</style>
